import React, { useState } from "react";
import useLanguage from "../utils/useLanguage";
import classes from "../components/Modal.module.css";
import closeIcon from "../assets/close_icon.png";
import { useTranslation } from "react-i18next";
import ChartComponent from "./ChartComponent";
import CustomButton from "../components/CustomButton";
import { Modal, Button } from "@material-ui/core";

const ChartModal = ({ open, onClose }) => {
  const { currentDirection } = useLanguage();
  const { t } = useTranslation("general");

  const [chartType, setChartType] = useState("bar");

  const toggleChartType = () => {
    setChartType((prevType) => {
      switch (prevType) {
        case "bar":
          return "line";
        case "line":
          return "doughnut";
        default:
          return "bar";
      }
    });
  };

  // Example data for testing
  const data = [
    { date: "2024-01-01", received: "1200", payed: "300" },
    { date: "2024-02-01", received: "800", payed: "200" },
    { date: "2024-03-01", received: "600", payed: "100" },
    { date: "2024-04-01", received: "500", payed: "500" },
    { date: "2024-05-01", received: "900", payed: "400" },
    { date: "2024-06-01", received: "1300", payed: "700" },
  ];

  // Extract dates for pagination
  const dates = data.map((item) => item.date);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="chart-modal">
        <section className={` chart-modal-header`}>
          <h2 className={classes.modalTitle}>{t("chartTitle")}</h2>
          <button className={"chart-modal-close-button"} onClick={onClose}>
            <img
              src={closeIcon}
              alt="close modal"
              className={classes.closeIcon}
              style={
                currentDirection === "rtl"
                  ? { left: "20px" }
                  : { right: "20px" }
              }
            />
          </button>
        </section>
        <section className="chart-modal-body">
          <CustomButton
            title={t("toggleChartType")}
            variant="blank"
            onClick={toggleChartType}
          />
          <ChartComponent data={data} chartType={chartType} dates={dates} />
        </section>
      </div>
    </Modal>
  );
};

export default ChartModal;
