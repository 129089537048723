import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

import ErrorBox from "../components/ErrorBox";
import classes from "./ResetPassword.module.css";

import Card from "../components/Card";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BOX_TYPE } from "../pages/SuccessPage";
import ErrorMessage from "../components/ErrorMessage";
import CustomButton from "../components/CustomButton";

export default function ResetPassword() {
  const { t } = useTranslation("general");
  const location = useLocation();
  const navigate = useNavigate();
  
  const [passwordFields, setPasswordFields] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [token, setToken] = useState("");
  const [id, setId] = useState("");
  const [lang, setLang] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const id = queryParams.get("id");
    const lang = queryParams.get("lang");
    setToken(token);
    setId(id);
    setLang(lang);
  }, [location]);

  const savePasswordChanges = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/changeForgottenPassword`,
        {
          newPassword: passwordFields.newPassword,
          confirmNewPassword: passwordFields.confirmPassword,
          token,
          userId: id,
        }
      );
      if (res?.data) {
        navigate("/successPage", {
          state: {
            title: "done",
            description: "passwordChanged",
            boxType: BOX_TYPE.CONFIRMATION,
          },
        });
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      console.log("Failed to reset password");
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordFields({ ...passwordFields, [name]: value });
  };

  return (
    <div style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
      <Container maxWidth="xs">
        <Card Element="div" cardTitle={t("resetPassword")} collapsable={false}>
          {error && <ErrorBox>{t(error)}</ErrorBox>}
          <div className={classes.inputContainer}>
            <label className={classes.inputLabel} htmlFor="newPassword">
              {t("newPassword")}
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={passwordFields.newPassword}
              onChange={handlePasswordChange}
            />
          </div>
          <div className={classes.inputContainer}>
            <label className={classes.inputLabel} htmlFor="confirmPassword">
              {t("confirmPassword")}
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={passwordFields.confirmPassword}
              onChange={handlePasswordChange}
            />
          </div>

          <div className={classes.passwordActions}>
            {/* <button
              className={classes.greenButton}
              onClick={savePasswordChanges}
            >
              Save
            </button> */}
            {error && (
              <div className={classes.centeredError}>
                <ErrorMessage>{t(error)}</ErrorMessage>
              </div>
            )}
            
            <div style={{ direction: lang === "en" ? "rtl" : "ltr" }}>
              <CustomButton
                title={t("save")}
                onClick={savePasswordChanges}
              />
            </div>

          </div>
        </Card>
      </Container>
    </div>
  );
}
