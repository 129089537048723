import { useState } from "react";

export function useDocs() {
  const [documents, setDocuments] = useState(null);

  const handlePrepareDocs = (event, imageType) => {
    const file = event.target.files[0];

    setDocuments({ imageFile: file, imageType: imageType });
  };

  return { documents, handlePrepareDocs };
}
