import classes from "./ErrorBox.module.css";
import useLanguage from "../utils/useLanguage";
import ErrorIcon from "../assets/error.svg";

export default function ErrorBox({ children }) {
  const { currentDirection } = useLanguage();

  return (
    <div className={classes.errorBox} style={{ direction: currentDirection }}>
      <img src={ErrorIcon} alt="Error" />
      <p className={classes.errorBoxText}>{children}</p>
    </div>
  );
}
