import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { useTranslation } from "react-i18next";

import ConfirmationBox from "../components/ConfirmationBox";
import ErrorBox from "../components/ErrorBox";

import ProfileCover from "../assets/logo.png";
import ProfilePicture from "../assets/accounting.png";
import previousIcon from "../assets/previous_icon.png";
import nextIcon from "../assets/next_icon.png";
import saveIcon from "../assets/save_icon.png";
import closeIcon from "../assets/close_icon.png";

import classes from "./Profile.module.css";
import useLanguage from "../utils/useLanguage";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";

const CustomerProfile = () => {
  const { t } = useTranslation("general");
  const navigate = useNavigate();
  const { currentDirection } = useLanguage();

  const [changePassword, setChangePassword] = useState(false);
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordChanged, setPasswordChanged] = useState({
    try: false,
    success: false,
  });
  const [backendError, setBackendError] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [userData, setUserData] = useState({
    userId: "",
    username: "",
    email: "",
    phoneNumber: "",
  });

  // ! TEMP
  const user = {};

  useEffect(() => {
    const id = parseInt(localStorage.getItem("userId"), 10);
    const email = localStorage.getItem("email");
    const username = localStorage.getItem("username");
    const phoneNumber = localStorage.getItem("phoneNumber");

    setUserData({ userId: id, username, email, phoneNumber });
  }, []);

  useEffect(() => {
    if (changePassword) {
      // Reset errors when user starts changing password
      setBackendError(false);
      setBackendErrorMessage("");
    }
  }, [changePassword]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleChangePassword = () => {
    setChangePassword(!changePassword);
    setPasswordFields({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordFields({ ...passwordFields, [name]: value });
  };

  const cancelChangePassword = () => {
    toggleChangePassword();
    setBackendError(false);
    setBackendErrorMessage("");
  };

  const savePasswordChanges = async () => {
    // Reset previous errors
    setBackendError(false);
    setBackendErrorMessage("");

    // Check for empty fields
    if (
      !passwordFields.currentPassword ||
      !passwordFields.newPassword ||
      !passwordFields.confirmPassword
    ) {
      setBackendError(true);
      setBackendErrorMessage(t("FillInAllPasswordFields"));
      return;
    }

    // Check if new password and confirm password match
    if (passwordFields.newPassword !== passwordFields.confirmPassword) {
      setBackendError(true);
      setBackendErrorMessage(t("NewPasswordsDoNotMatch"));
      return; // Exit if passwords do not match
    }

    setBackendError(false);

    if (passwordFields.newPassword === passwordFields.confirmPassword) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/user/${userData.userId}/changepass`, // Use userData instead of user
          {
            passwords: passwordFields,
          }
        );
        setPasswordChanged({ try: true, success: true });
      } catch (error) {
        setPasswordChanged({ try: true, success: false });
        const errorMessage = error.response?.data?.error || "An error occurred";
        setBackendErrorMessage(t(errorMessage));
      }

      toggleChangePassword();
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}/editUser`,
        {
          id: userData.userId, // Change this line
          username: userData.username,
          email: userData.email,
          phoneNumber: userData.phoneNumber,
        }
      );

      if (response?.data) {
        setUserData(response?.data);
        localStorage.setItem("userId", response?.data?.id);
        localStorage.setItem("email", response?.data?.email);
        localStorage.setItem("username", response?.data?.username);
        localStorage.setItem("phoneNumber", response?.data?.phoneNumber);
        setEditMode(false);
      }
    } catch (error) {
      console.error("Failed to update user data", error);
      const errorMessage =
        error.response?.data?.error || "An unknown error occurred";
      // setBackendError(false);
      // setBackendErrorMessage(errorMessage);
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div className={classes.profileContainer}>
      <section className={classes.profile}>
        <CustomButton
          title={t("back")}
          variant="blank"
          icon={currentDirection === "ltr" ? previousIcon : nextIcon}
          iconSize={14}
          onClick={() => navigate("/dashboard")}
        />
        <div className={classes.profileCard}>
          <section className={classes.header}>
            <h2 className={classes.profileGreeting}>
              <span>{t("hello")}</span>
              <span className={classes.name}>{userData.username}</span>
            </h2>
            <img
              src={ProfileCover}
              alt="profile cover"
              className={classes.coverImage}
            />
          </section>
          <section className={classes.content}>
            <img
              src={ProfilePicture}
              alt="profile"
              className={
                currentDirection === "ltr" ? classes.avatar : classes.avatarRtl
              }
            />
            <div
              className={
                currentDirection === "ltr"
                  ? classes.profileActions
                  : classes.profileActionsRtl
              }
            >
              {editMode ? (
                <>
                  <CustomButton
                    title={t("cancel")}
                    onClick={toggleEditMode}
                    variant="blank"
                    icon={closeIcon}
                    iconSize={14}
                  />
                  <CustomButton
                    onClick={handleSubmit}
                    title={t("confirm")}
                    icon={saveIcon}
                    iconSize={16}
                  />
                </>
              ) : (
                <CustomButton
                  title={t("editProfile")}
                  variant="blank"
                  onClick={toggleEditMode}
                />
              )}
            </div>
            <div className={classes.contentRow}>
              <section className={classes.contentRow}>
                <div className={classes.changePasswordSection}>
                  <div className={classes.readingMode}>
                    <h3>{t("generalInfo")}</h3>
                    {editMode ? (
                      <div className={classes.editingMode}>
                        <div className={classes.inputContainer}>
                          <label
                            className={classes.inputLabel}
                            htmlFor="username"
                          >
                            {t("fullName")}
                          </label>
                          <CustomInput
                            type="text"
                            id="username"
                            name="username"
                            value={userData?.username}
                            onChange={handleInputChange}
                            thick
                          />
                        </div>
                        <div className={classes.inputContainer}>
                          <label className={classes.inputLabel} htmlFor="email">
                            {t("email")}
                          </label>
                          <CustomInput
                            type="email"
                            id="email"
                            name="email"
                            value={userData?.email}
                            onChange={handleInputChange}
                            thick
                            readonly={true}
                          />
                        </div>
                        <div className={classes.inputContainer}>
                          <label
                            className={classes.inputLabel}
                            htmlFor="phoneNumber"
                          >
                            {t("phoneNumber")}
                          </label>
                          <CustomInput
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={userData?.phoneNumber}
                            onChange={handleInputChange}
                            thick
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={classes.info}>
                        <p>
                          {t("fullName")}: {userData.username}
                        </p>
                        <p>
                          {t("email")}: {userData.email}
                        </p>
                        <p>
                          {t("phoneNumber")}: {userData.phoneNumber}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
            <div className={classes.contentRow}>
              <section className={classes.contentRow}>
                {changePassword ? (
                  <div className={classes.changePasswordSection}>
                    <div className={classes.changePasswordSection}>
                      <h3>{t("changePassword")}</h3>
                      <div className={classes.inputContainer}>
                        <label
                          className={classes.inputLabel}
                          htmlFor="currentPassword"
                        >
                          {t("currentPassword")}
                        </label>
                        <input
                          type="password"
                          id="currentPassword"
                          name="currentPassword"
                          value={passwordFields.currentPassword}
                          onChange={handlePasswordChange}
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <label
                          className={classes.inputLabel}
                          htmlFor="newPassword"
                        >
                          {t("newPassword")}
                        </label>
                        <input
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          value={passwordFields.newPassword}
                          onChange={handlePasswordChange}
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <label
                          className={classes.inputLabel}
                          htmlFor="confirmPassword"
                        >
                          {t("confirmNewPassword")}
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          value={passwordFields.confirmPassword}
                          onChange={handlePasswordChange}
                        />
                      </div>

                      {backendError && (
                        <ErrorBox>{backendErrorMessage}</ErrorBox>
                      )}

                      <div className={classes.passwordActions}>
                        <CustomButton
                          title={t("cancel")}
                          variant="blank"
                          onClick={cancelChangePassword}
                          icon={closeIcon}
                          iconSize={14}
                        />
                        <CustomButton
                          title={t("confirm")}
                          onClick={savePasswordChanges}
                          icon={saveIcon}
                          iconSize={16}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classes.changePasswordSection}>
                    <h3>{t("changePassword")}</h3>
                    <div className={classes.primaryButton}>
                      <CustomButton
                        title={t("changePassword")}
                        variant="blank"
                        onClick={toggleChangePassword}
                      />
                    </div>
                    {passwordChanged.try &&
                      (passwordChanged.success ? (
                        <ConfirmationBox>
                          {t("passwordChanged")}
                        </ConfirmationBox>
                      ) : (
                        <ErrorBox>{backendErrorMessage}</ErrorBox>
                      ))}
                  </div>
                )}
              </section>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default CustomerProfile;
