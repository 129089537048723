import React, { useState, useRef, useEffect } from "react";
import classes from "./Dropdown.module.css";
import arrowIcon from "../assets/down-arrow.png";
import { useTranslation } from "react-i18next";

const Dropdown = ({
  options,
  selected,
  setSelected,
  thick,
  options2,
  flex,
  errorText,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation("general");

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelected(item);
    if (onChange) {
      onChange(item);
    }
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleDropdown();
    }
  };

  const showError = !selected && errorText;

  return (
    <div
      className={`${classes.dropdown} ${flex ? classes.fWidthDropDown : ""}`}
      ref={dropdownRef}
    >
      {showError && <div className="error-message">{t(errorText)}</div>}
      <div
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        tabIndex={0} // Make the dropdown toggle focusable
        className={`${classes.dropdownToggle} ${isOpen ? classes.opened : ""} ${
          thick ? classes.thick : ""
        }`}
      >
        {options && typeof selected === "object"
          ? t(selected.title)
          : null
            // <span>{null}</span>
        }
        {
          options2 && typeof selected === "object" ? selected.name : null
          // <span>{null}</span>
        }
        <img
          src={arrowIcon}
          alt="Dropdown arrow"
          className={classes.arrowIcon}
        />
      </div>
      {isOpen && (
        <>
          <div className={classes.overlay} onClick={toggleDropdown}></div>
          <ul className={classes.dropdownMenu}>
            {options2 ? (
              <>
                {Array.isArray(options2) &&
                  options2.map((option, index) => (
                    <li
                      key={index}
                      className={classes.dropdownItem}
                      onClick={() => handleItemClick(option)}
                      tabIndex={0} // Make each dropdown item focusable
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          handleItemClick(option);
                        }
                      }}
                    >
                      {typeof option === "object" ? option.name : option}
                    </li>
                  ))}
              </>
            ) : (
              <>
                {Array.isArray(options) &&
                  options.map((option, index) => (
                    <li
                      key={index}
                      className={classes.dropdownItem}
                      onClick={() => handleItemClick(option)}
                      tabIndex={0} // Make each dropdown item focusable
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          handleItemClick(option);
                        }
                      }}
                    >
                      {typeof option === "object" ? t(option.title) : option}
                    </li>
                  ))}
              </>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Dropdown;
