import { useState, useEffect } from "react";
import axios from "axios";
import docsIcon from "../assets/docs_icon.png";
import classes from "./PDFview.module.css";

export const HEIGHT = {
  FULL: "full-height",
};

const PDFViewer = ({ fileId, height }) => {
  const [pdfUrl, setPDFurl] = useState("");

  useEffect(() => {
    getPdfUrl();
  }, []);

  const getPdfUrl = async (open) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/${fileId}`,
        {
          responseType: "blob",
        }
      );
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const frontpdfUrl = URL.createObjectURL(pdfBlob);
      setPDFurl(frontpdfUrl);
      if (open) {
        window.open(pdfUrl);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  return (
    <div className={classes.mainDiv}>
      <div
        onClick={() => getPdfUrl(true)}
        className={height !== HEIGHT.FULL ? classes.overlay : null}
      ></div>
      <iframe
        src={pdfUrl}
        title={document.filename}
        className={`${classes.iframe} ${
          height === HEIGHT.FULL ? classes.fullHeightIframe : null
        }`}
      />
    </div>
  );
};

export default PDFViewer;
