// Login.js
import React, { useContext, useState } from "react";
import classes from "./Login.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import useLanguage from "../utils/useLanguage";
import CustomInput from "./CustomInput";
import logo from "../assets/logo.png";
import { UserAuth } from "../utils/AuthContext";
import ErrorBox from "../components/ErrorBox";
import ErrorMessage from "../components/ErrorMessage";
import InputLabel from "../components/InputLabel";
import InformationBox from "../components/InformationBox";
import Modal from "../components/Modal";
import CustomButton from "../components/CustomButton";

const Login = ({ setIsAuthenticated }) => {
  const { currentDirection } = useLanguage();
  // Determine the language based on currentDirection
  const language = currentDirection === "rtl" ? "ar" : "en";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [serverErrorState, setServerErrorState] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");

  const [forgetPasswordError, setForgetPasswordError] = useState(false);
  const [forgetPasswordErrorMessage, setForgetPasswordErrorMessage] =
    useState("");
  const [forgetPassword, setForgetPassword] = useState(false);
  const [pageTwo, setPageTwo] = useState({ active: false });

  const navigate = useNavigate();
  const { t } = useTranslation("general");

  const { userData, setUserData } = useContext(UserAuth);

  const handleGoToGegister = async () => {
    navigate("/register");
  };

  const handleLogin = async () => {
    // Validate input fields
    if (!email || !password) {
      setError(true);
      setErrorMessage(false);
      setServerErrorState(false);
      return;
    }

    // Email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(false);
      setServerErrorState(false);
      setErrorMessage(true);
      return;
    }

    setError(false);
    setErrorMessage(false);
    setServerErrorState(false);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/login`, {
        email,
        password,
      });

      if (!response) {
        return console.error("Failed to login");
      }

      const { token, user } = response.data;

      setUserData({ token, user });
      // setIsAuthenticated(token);

      // Store the token, user ID, email, and username in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("userType", "u-u");
      localStorage.setItem("userId", user.id);
      localStorage.setItem("supplierId", user?.supplierId);
      localStorage.setItem("email", user.email);
      localStorage.setItem("username", user.username);
      localStorage.setItem("phoneNumber", user?.phoneNumber);

      // Set the authentication state in App.js
      setIsAuthenticated(token); // Pass setIsAuthenticated as a prop from App.js

      navigate("/dashboard");
    } catch (error) {
      const serverErrorRespone = error.response.data.error;
      setServerErrorMessage(t(serverErrorRespone));
      if (error.response) {
        setServerErrorState(true);
      } else {
        setServerErrorState(true);
      }
    }
  };

  const handleForgetPassword = async () => {
    if (!email) {
      setForgetPasswordError(true);
      setForgetPasswordErrorMessage("emailInputFieldCannotBeEmpty");
      return;
    }

    // Email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setForgetPasswordError(true);
      setForgetPasswordErrorMessage("invalidEmail");
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/forgetPassword`,
        { email, language }
      );

      if (res?.data) {
        setForgetPasswordError(false);
        setForgetPasswordErrorMessage("");
        setPageTwo((prev) => ({ ...prev, active: true }));
      } else {
        setForgetPasswordError(true);
        setForgetPasswordErrorMessage("passwordNotChanged");
      }
    } catch (err) {
      setForgetPasswordError(true);
      setForgetPasswordErrorMessage(
        err?.response?.data?.message || "passwordNotChanged"
      );
    }
  };

  const handleCloseForgetPasswordModal = async () => {
    setForgetPassword(false);
    setForgetPasswordError(false);
    setForgetPasswordErrorMessage("");
  };

  return (
    <div className="onboarding-container">
      <div className="onboarding">
        <div className="main-wrapper">
          <div className="language-switcher-container">
            <div className="language-switcher">
              <LanguageSwitcher />
            </div>
          </div>
          <div>
            <img src={logo} alt="Ehsibly" className="website-logo" />
            {/* <h2 className="logo-text">{t("websiteTitle")}</h2> */}
            <p className="intro-text">
              <span className="bold-text">{t("websiteTitle")} </span>
              {t("websiteDescription")}
            </p>
          </div>

          <form className="rigister-login-form" id="login-form">
            <h2>{t("login")}</h2>

            {error && <ErrorBox>{t("emptyInputFields")}</ErrorBox>}
            {errorMessage && <ErrorBox>{t("invalidEmail")}</ErrorBox>}
            {serverErrorState && <ErrorBox>{serverErrorMessage}</ErrorBox>}

            <div>
              <InputLabel title={t("email")} />
              {errorMessage && !error && (
                <ErrorMessage>{t("invalidEmail")}</ErrorMessage>
              )}
              <CustomInput
                placeholder={t("email")}
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                errorText={
                  errorMessage
                    ? t("invalidEmail")
                    : error && !email
                    ? t("inputFieldCannotBeEmpty")
                    : ""
                }
              />
            </div>

            <div>
              <InputLabel title={t("password")} />
              <CustomInput
                placeholder={t("password")}
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                errorText={
                  error && !password ? t("inputFieldCannotBeEmpty") : ""
                }
              />
            </div>

            {error && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorMessage>{t("emptyInputFields")}</ErrorMessage>
              </div>
            )}

            {errorMessage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorMessage>{t("invalidEmail")}</ErrorMessage>
              </div>
            )}

            <button
              id="forgot-password-button"
              className={classes.forgetPasswordTextButton}
              type="button"
              onClick={() => setForgetPassword(true)}
            >
              {t("forgetPassword")}
            </button>

            {serverErrorMessage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorMessage>{serverErrorMessage}</ErrorMessage>
              </div>
            )}

            <button type="button" onClick={handleLogin}>
              {t("login")}
            </button>

            {forgetPassword && (
              <Modal
                pageTwo={pageTwo}
                setPageTwo={setPageTwo}
                className={classes.modal}
                noBack={true}
                onClose={() => {
                  handleCloseForgetPasswordModal();
                }}
              >
                {pageTwo?.active ? (
                  <>
                    <h2 className={classes.modalTitle}>{t("done")}</h2>
                    <InformationBox>
                      {t("resetPasswordLinkSent")}
                    </InformationBox>
                  </>
                ) : (
                  <>
                    <h2 className={classes.modalTitle}>
                      {t("forgetPassword")}
                    </h2>
                    {forgetPasswordError && (
                      <ErrorBox>{t(forgetPasswordErrorMessage)}</ErrorBox>
                    )}
                    <h4 className={classes.modalSubTitle}>
                      {t("enterYourEmail")}
                    </h4>
                    <div className={classes.inputContainer}>
                      <label className={classes.inputLabel} htmlFor="email">
                        {t("email")}:
                      </label>

                      {forgetPasswordError && (
                        <div className={classes.centeredError}>
                          <ErrorMessage>
                            {t(forgetPasswordErrorMessage)}
                          </ErrorMessage>
                        </div>
                      )}

                      <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <CustomButton
                      title={t("send")}
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default form behavior
                        handleForgetPassword();
                      }}
                    />
                  </>
                )}
              </Modal>
            )}

            <p className="login-or-register">{t("or")}</p>

            <button type="button" onClick={handleGoToGegister}>
              {t("register")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
