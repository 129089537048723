// Register.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import CustomInput from "./CustomInput";
import logo from "../assets/logo.png";
import ErrorBox from "../components/ErrorBox";
import ErrorMessage from "../components/ErrorMessage";
import InputLabel from "../components/InputLabel";
import useLanguage from "../utils/useLanguage";

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // ! Change to false to show the message
  const [developerMode, setDeveloperMode] = useState(true);

  const { currentDirection } = useLanguage();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation("general");

  const handleGoToLogin = async () => {
    navigate("/login");
  };

  const handleRegister = async () => {
    if (!email || !username || !password || !phoneNumber) {
      setError(true);
      setErrorMessage(t("emptyInputFields"));
      return;
    }

    // Check if password and confirm password match
    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage(t("passwordsDoNotMatch"));
      return;
    }

    // Email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && username && password && phoneNumber) {
      setError(false);
      if (!emailPattern.test(email)) {
        setError(true);
        setErrorMessage(t("invalidEmail"));
        return;
      }
    }

    setError(false);
    setErrorMessage("");

    // Determine the language based on currentDirection
    const language = currentDirection === "rtl" ? "ar" : "en";

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/register`,
        {
          email,
          username,
          password,
          phoneNumber,
          language,
        }
      );
      // Navigate to login if registration is successful
      navigate("/login");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorKey = error.response.data.error;
        console.log("errorKey:", errorKey);
        // Map backend errors to user-friendly messages
        switch (errorKey) {
          case "emailAlreadyInUse":
            setError(true);
            setErrorMessage(t("emailAlreadyInUse"));
            break;
          case "invalidPhoneNumber":
            setError(true);
            setErrorMessage(t("invalidPhoneNumber"));
            break;
          default:
            setError(true);
            setErrorMessage(t("unexpectedError"));
            break;
        }
      } else {
        console.error("Network or other error:", error.message);
        setError(true);
        setErrorMessage(t("networkError"));
      }
    }
  };

  useEffect(() => {}, [errorMessage]);

  return (
    <div className="onboarding-container">
      <div className="onboarding">
        <div className="main-wrapper">
          <div className="language-switcher-container">
            <div className="language-switcher">
              <LanguageSwitcher />
            </div>
          </div>
          <div>
            <img src={logo} alt="Ehsibly" className="website-logo" />
            {/* <h2 className="logo-text">{t("websiteTitle")}</h2> */}
            <p className="intro-text">
              <span className="bold-text">{t("websiteTitle")} </span>
              {t("websiteDescription")}
            </p>
          </div>
          {!developerMode ? (
            <div className="rigister-login-form">
              <p className="text contact-us-title-text">
                Please contact us at the below contact information and we will
                help you to create an acount.
              </p>
              <p className="text">
                <strong>Email:</strong>
                <i type="email"> info.lykvyd@gmail.com</i>
              </p>
              <p className="text">
                <strong>Phone:</strong>
                <i type="phone"> 009647800092734</i>
              </p>
            </div>
          ) : (
            <form className="rigister-login-form" id="login-form">
              <h2>{t("register")}</h2>

              {error && <ErrorBox>{errorMessage}</ErrorBox>}
              {/* {errorMessage && <ErrorBox>{t("invalidEmail")}</ErrorBox>} */}

              <div>
                <InputLabel title={t("username")} />
                <CustomInput
                  placeholder={t("username")}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  errorText={
                    error && !username ? t("inputFieldCannotBeEmpty") : ""
                  }
                />
              </div>

              <div>
                <InputLabel title={t("email")} />
                {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <CustomInput
                  placeholder={t("email")}
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  errorText={
                    errorMessage
                      ? t("invalidEmail")
                      : error && !email
                      ? t("inputFieldCannotBeEmpty")
                      : ""
                  }
                />
              </div>

              <div>
                <InputLabel title={t("phoneNumber")} />
                <CustomInput
                  placeholder={t("phoneNumber")}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  errorText={
                    error && !phoneNumber ? t("inputFieldCannotBeEmpty") : ""
                  }
                />
              </div>

              <div>
                <InputLabel title={t("password")} />
                <CustomInput
                  placeholder={t("password")}
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  errorText={
                    error && !password ? t("inputFieldCannotBeEmpty") : ""
                  }
                />
              </div>

              <div>
                <InputLabel title={t("confirmPassword")} />
                <CustomInput
                  placeholder={t("confirmPassword")}
                  value={confirmPassword}
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  errorText={
                    error && !confirmPassword
                      ? t("inputFieldCannotBeEmpty")
                      : ""
                  }
                />
              </div>

              {error && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </div>
              )}

              <button type="button" onClick={handleRegister}>
                {t("register")}
              </button>

              <p className="login-or-register">{t("or")}</p>

              <button type="button" onClick={handleGoToLogin}>
                {t("login")}
              </button>
            </form>
          )}
        </div>
      </div>
      <div className="fake-footer"></div>
    </div>
  );
};

export default Register;
