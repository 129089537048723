// src/components/Navigation.js
import React from "react";
import classes from "./InputLabel.module.css";
import useLanguage from "../utils/useLanguage";

const InputLabel = ({ title }) => {
  const { language } = useLanguage();
  return (
    <label className= {`${classes.inputLabel} ${language === "ar" ? classes.right : classes.left}`}>
      {title}
    </label>
  );
};

export default InputLabel;
