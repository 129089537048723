import classes from "./ConfirmationBox.module.css";
import useLanguage from "../utils/useLanguage";
import DoneIcon from "../assets/done-all.svg";

export default function ConfirmationBox({ children }) {
    const { currentDirection } = useLanguage();

  return (
    <div
      className={classes.confirmationBox}
      style={{ direction: currentDirection }}
    >
      <p className={classes.confirmationBoxText}>{children}</p>
      <img src={DoneIcon} alt="Error" />
    </div>
  );
}
