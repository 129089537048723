import { Link } from "react-router-dom";

export default function CustomLink({ children, ...props }) {
  const linkStyles = {
    textDecoration: "none",
  };

  return (
    <Link {...props} style={linkStyles}>
      {children}
    </Link>
  );
}
