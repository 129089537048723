import { Container } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "../components/Card";
import InformationBox from "../components/InformationBox";
import ConfirmationBox from "../components/ConfirmationBox";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";

export const BOX_TYPE = {
  CONFIRMATION: "confirmation",
  INFORMATION: "information",
};

export default function SuccessPage() {
  const { t } = useTranslation("general");
  const location = useLocation();
  const { title, description, boxType } = location.state || {};


  const navigate = useNavigate();
  
  const navigateToLogin = async () => {
    navigate("/login");
  };

  return (
    <Container maxWidth="xs">
      <Card Element="div" cardTitle={t(title)} collapsable={false}>
        {boxType === BOX_TYPE.CONFIRMATION ? (
          <>
            <ConfirmationBox>{t(description)}</ConfirmationBox>
            <CustomButton
              title={t("login")}
              onClick={navigateToLogin}
            />
          </>
        ) : (
          <InformationBox>{t(description)}</InformationBox>
        )}
      </Card>
    </Container>
  );
}
