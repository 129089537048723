// App.js
import { useState, useEffect } from "react";
import { jwtDecode as jwt_decode } from "jwt-decode";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";
// import InvoiceTable from "./components/InvoiceTable";
import Register from "./components/Register";
import Login from "./components/Login";
import InvoicesManagement from "./pages/InvoicesManagement";
import ContainerPrices from "./pages/ContainerPrices";
import ViewInvoice from "./pages/ViewInvoice";
import PrintInvoices from "./pages/PrintInvoices";
import DataProvider from "./utils/DataContext";
import ScrollToTop from "./components/ScrollToTop";
import PrintDocuments from "./components/PrintDocuments";
import Header from "./components/Header";
import Profile from "./pages/Profile";
import VerfiyEmail from "./pages/VerfiyEmail";
import ResetPassword from "./pages/ResetPassword";
import SuccessPage from "./pages/SuccessPage";
import SuppliersPage from "./pages/SuppliersPage";

import AdminsLogin from "./pages/AdminsLogin";
import ControlPanel from "./pages/ControlPanel";
import UsersPage from "./pages/UsersPage";
import AdminsPage from "./pages/AdminsPage";

const Layout = ({ element, showHeader, setIsAuthenticated }) => {
  return (
    <DataProvider>
      {showHeader && <Header setIsAuthenticated={setIsAuthenticated} />}
      {element}
    </DataProvider>
  );
};
const App = () => {
  const getShowHeader = (path) => {
    const headerRoutes = [
      "/dashboard",
      "/containerPrices",
      "/invoice/:id",
      "/print",
      "/printDocuments",
      "/profile",
      "/ControlPanel",
    ];
    return headerRoutes.some((route) => path.match(route));
  };

  function ProtectedResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const id = searchParams.get("id");

    if (token && id) {
      return <ResetPassword />;
    } else {
      return <Navigate to="/page-not-found" />;
    }
  }

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("SuperAdminToken");

    localStorage.removeItem("userType");
    
    localStorage.removeItem("userId");
    localStorage.removeItem("supplierId");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("phoneNumber");
    
    setIsAuthenticated(null);
    setIsAuthenticatedAdmin(null);
  };

  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Failed to decode token", error);
      return true; // If there's an error decoding, consider the token expired
    }
  };

  // Check for valid token on initial load and whether it's expired
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    return token && !isTokenExpired(token);  // Check if token is valid and not expired
  });


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && isTokenExpired(token)) {
      logout();
    }
  }, []);

  
  const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(() => {
    const adminToken = localStorage.getItem("SuperAdminToken") || localStorage.getItem("adminToken");
    return adminToken ? true : false;  // If there's a token, user is authenticated
  });

  const ProtectedRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  const AdminProtectedRoute = ({ isAuthenticatedAdmin, children }) => {
    return isAuthenticatedAdmin ? children : <Navigate to="/admin-login" />;
  };

  return (
    <Router>
      <>
        <Routes>
          <Route
            path="/admin-login"
            element={
              <AdminsLogin
              isAuthenticatedAdmin={isAuthenticatedAdmin}
                setIsAuthenticatedAdmin={setIsAuthenticatedAdmin}
              />
            }
          />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/admins" element={<AdminsPage />} />
          <Route
            path="/ControlPanel"
            element={
              <AdminProtectedRoute isAuthenticatedAdmin={isAuthenticatedAdmin}>
                <Layout
                  element={
                    <ControlPanel setIsAuthenticated={setIsAuthenticatedAdmin} />
                  }
                  showHeader={getShowHeader("/ControlPanel")}
                  setIsAuthenticated={setIsAuthenticatedAdmin}
                />
              </AdminProtectedRoute>
            }
          />

          <Route path="/register" element={<Register />} />

          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" />
              ) : (
                <Login
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={setIsAuthenticated}
                />
              )
            }
          />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Layout
                  element={
                    <InvoicesManagement
                      setIsAuthenticated={setIsAuthenticated}
                    />
                  }
                  showHeader={getShowHeader("/dashboard")}
                  setIsAuthenticated={setIsAuthenticated}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/containerPrices"
            element={
              <Layout
                element={<ContainerPrices />}
                showHeader={getShowHeader("/containerPrices")}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route
            path="/invoice/:id"
            element={
              <Layout
                element={<ViewInvoice />}
                showHeader={getShowHeader("/invoice/:id")}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route
            path="/print"
            element={
              <Layout
                element={<PrintInvoices />}
                showHeader={getShowHeader("/print")}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route
            path="/printDocuments"
            element={
              <Layout
                element={<PrintDocuments />}
                showHeader={getShowHeader("/printDocuments")}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <Layout
                element={<Profile />}
                showHeader={getShowHeader("/profile")}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route path="/forget-password" element={<ProtectedResetPassword />} />
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
            exact
          />
          <Route path="/viewSuppliersPage" element={<SuppliersPage />} />
          <Route path="/verify-email" element={<VerfiyEmail />} />
          <Route path="/successPage" element={<SuccessPage />} />
        </Routes>
        <ScrollToTop />
      </>
    </Router>
  );
};

export default App;
