import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';


const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: 'gray',
  '&.Mui-checked': {
    color: '#79329a', 
  },
}));

export default CustomCheckbox;
