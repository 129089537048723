import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classes from "./ScrollToTop.module.css";
import topIcon from "../assets/top_icon.png";

export default function ScrollToTop() {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className={classes.container}>
      {show && (
        <div className={classes.main} onClick={scrollToTop}>
          <img src={topIcon} alt="Scroll to top" className={classes.icon} />
        </div>
      )}
    </section>
  );
}
