import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import classes from "../pages/ViewInvoice.module.css";

// * Custom utils
import { toDate } from "../utils/toDate";
import { toMoney } from "../utils/toMoney";
import { getCleanedInvoiceNumber } from "../utils/getCleanedInvoiceNumber";
import Header from "../components/Header";
import { DataContext } from "../utils/DataContext";
import Image from "./Image";

export default function PrintDocuments() {
  const { t } = useTranslation("general");
  const [state, setState] = useState([]);
  const { selectedSupplier } = useContext(DataContext);

  useEffect(() => {
    const storedRows = sessionStorage.getItem("selectedRows");
    if (storedRows) {
      setState(JSON.parse(storedRows));
    }

    const handleBeforePrint = () => {
      document.title = "Company name";
    };

    const handleAfterPrint = () => {
      document.title = "Ehsibly";
    };

    window.print();

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  return (
    <main className={classes.viewInvoicePage}>
      <div className="header-container ">
        {/* <Header /> */}
      </div>
      <div className={classes.container}>
        {state?.map((data) => (
          <span>{JSON.stringify(data)}</span>
          // <Image
          //   imagePath={data[0]?.path}
          //   className={classes.sliderImage}
          // />
        ))}
      </div>
    </main>
  );
}
