import classes from "./ErrorMessage.module.css";

import { useTranslation } from "react-i18next";

export default function ErrorMessage({ children }) {
  const { t } = useTranslation("general");

  return (
    <p className={classes.errorMessage}>
      {children}
    </p>
  );
}
