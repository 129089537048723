import { createContext, useState } from "react";

export const UserAuth = createContext(null);

export default function UserAuthProvider({ children }) {
  const [userData, setUserData] = useState();

  return (
    <UserAuth.Provider value={{ userData, setUserData }}>
      {children}
    </UserAuth.Provider>
  );
}
