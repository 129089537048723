// im-app/src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Languages files
import enTranslations from "./locales/en.json";
import arTranslations from "./locales/ar.json";
import UserAuthProvider from "./utils/AuthContext";

i18n.use(LanguageDetector).init({
  resources: {
    en: enTranslations,
    ar: arTranslations,
  },
  fallbackLng: "en",
  detection: {
    order: ["cookie", "localStorage", "navigator"],
    caches: ["cookie", "localStorage"],
  },
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <UserAuthProvider>
      <App />
    </UserAuthProvider>
  </I18nextProvider>
);

// reportWebVitals();
