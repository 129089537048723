// src/pages/AdminsPage.js
import { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import CustomCheckbox from "../components/CustomCheckbox";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import useLanguage from "../utils/useLanguage";
import { useNavigate } from "react-router";
import nextIcon from "../assets/next_icon.png";
import previousIcon from "../assets/previous_icon.png";
import deleteIcon from "../assets/delete_Icon.png";
import saveIcon from "../assets/save_icon.png";
import closeIcon from "../assets/close_icon.png";
import editIcon from "../assets/edit_icon.png";
import addIcon from "../assets/add_icon.png";
import ErrorBox from "../components/ErrorBox";

export default function AdminsPage() {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  const [admins, setAdmins] = useState([]);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [networkError, setNetworkError] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [adminModal, setAdminModal] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [adminFields, setAdminFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [adminsCurrentPage, setAdminsCurrentPage] = useState(1);
  const [adminsTotalPages, setAdminsTotalPages] = useState(1);
  const [adminsTotalRecords, setAdminsTotalRecords] = useState(0);

  const [message, setMessage] = useState("");
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [newAdminFields, setNewAdminFields] = useState({
    userName: "",
    adminEmail: "",
    password: "",
    confirmPassword: "",
    isSuperAdmin: false,
    superAdminPassword: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    getAdmins();
  }, [adminsCurrentPage, searchTerm]);

  const getAdmins = async () => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");
    const adminId = parseInt(localStorage.getItem("adminId"), 10);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getAdmins`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Admin-Id": adminId,
          },
          params: {
            page: adminsCurrentPage,
            limit: 5,
            search: searchTerm,
          },
        }
      );

      if (response.data && response.data.admins) {
        setAdmins(response.data.admins);
        setFilteredAdmins(response.data.admins);
        setAdminsTotalPages(response.data.totalPages);
        setAdminsTotalRecords(response.data.totalAdmins);
      } else {
        console.error("Failed to get the admins");
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to get the admins", error);
    }
  };

  const openAdminModal = (admin) => {
    setSelectedAdmin(admin);
    setAdminFields({
      userName: admin.username,
      adminEmail: admin.email,
    });
    setIsEditing(false);
    setAdminModal(true);
  };

  const handleInputChange = (e, fieldName) => {
    setAdminFields({
      ...adminFields,
      [fieldName]: e.target.value,
    });
  };

  const handleSaveAdmin = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/editAdmin`,
        {
          id: selectedAdmin?.id,
          username: adminFields.userName,
          email: selectedAdmin.email,
        }
      );

      console.log("selectedAdmin: ", selectedAdmin);
      console.log("selectedAdmin?.id: ", selectedAdmin?.id);
      console.log("selectedAdmin.userName: ", selectedAdmin.username);
      console.log("New: adminFields.userName,: ", adminFields.userName);
      console.log("selectedAdmin.userEmail: ", selectedAdmin.email);

      if (response.data.message === "Admin updated successfully") {
        await getAdmins();
        setAdminModal(false);
      } else {
        console.error("Failed to update admin:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating admin:", error);
    }
  };

  const handleCancel = () => {
    setRemoveAdminModal(false);
    setAdminModal(false);
    setSelectedAdmin(null);
  };

  const openRemoveAdminModal = (admin) => {
    setSelectedAdmin(admin);
    setRemoveAdminModal(true);
  };

  const [showRemoveAdminModal, setRemoveAdminModal] = useState(false);
  const handleDeleteAdmin = async () => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");
    console.log(token);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/deleteAdmin/${selectedAdmin.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Admin deleted successfully") {
        await getAdmins(); // Refresh the admin list
        setRemoveAdminModal(false);
      } else {
        console.error("Failed to delete admin:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
  };

  const openAddAdminModal = () => {
    setAddAdminModal(true);
    setNewAdminFields({
      userName: "",
      adminEmail: "",
      password: "",
      confirmPassword: "",
      isSuperAdmin: false,
      superAdminPassword: "",
    });
  };

  const handleNewAdminInputChange = (e, fieldName) => {
    const value =
      fieldName === "isSuperAdmin" ? e.target.checked : e.target.value;
    setNewAdminFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const handleCreateAdmin = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const adminId = parseInt(localStorage.getItem("adminId"), 10);

      // Password match validation
      if (newAdminFields.password !== newAdminFields.confirmPassword) {
        setError(true);
        setErrorMessage("Passwords do not match");
        return;
      }

      if (
        !newAdminFields.adminEmail ||
        !newAdminFields.userName ||
        !newAdminFields.password ||
        !newAdminFields.superAdminPassword
      ) {
        setError(true);
        setErrorMessage(t("emptyInputFields"));
        return;
      }
      
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/createAdmin`,
        {
          username: newAdminFields.userName,
          email: newAdminFields.adminEmail,
          password: newAdminFields.password,
          isSuperAdmin: newAdminFields.isSuperAdmin,
          superAdminPassword: newAdminFields.superAdminPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Admin-Id": adminId,
          },
        }
      );

      setMessage(response.data.message);
      if (response.data.message === "Admin created successfully") {
        setError(false);
        setErrorMessage("");
        setAddAdminModal(false);
        await getAdmins();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorKey = error.response.data.error;
        console.log("errorKey:", errorKey);
        // Map backend errors to user-friendly messages
        switch (errorKey) {
          case "emailAlreadyInUse":
            setError(true);
            setErrorMessage(t("emailAlreadyInUse"));
            break;
          case "invalidPhoneNumber":
            setError(true);
            setErrorMessage(t("invalidPhoneNumber"));
            break;
          default:
            setError(true);
            setErrorMessage(t("unexpectedError"));
            break;
        }
      } else {
        console.error("Network or other error:", error.message);
        setError(true);
        setErrorMessage(t("networkError"));
      }
    }
  };

  const handleCancelAddAdmin = () => {
    setError(false);
    setErrorMessage("");
    setAddAdminModal(false);
  };

  return (
    <div className="view-users-page">
      <Container
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <CustomButton
          title={t("back")}
          variant="blank"
          icon={currentDirection === "ltr" ? previousIcon : nextIcon}
          iconSize={14}
          onClick={() => navigate("/controlPanel")}
        />

        <div className="users-title-section">
          <Typography variant="h4" gutterBottom style={{ marginTop: "25px" }}>
            {t("admins", { defaultValue: "admins" })}
          </Typography>

          <CustomButton
            title={t("createAdmin")}
            variant="blank"
            icon={addIcon}
            iconSize={20}
            onClick={openAddAdminModal}
          />
        </div>

        <CustomInput
          name="userName"
          value={searchTerm}
          placeholder={searchTerm ? "" : t("search")}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {networkError ? (
          <Typography color="error">
            {t("error.network", { defaultValue: "Network error occurred." })}
          </Typography>
        ) : filteredAdmins.length === 0 ? (
          <Typography>
            {t("admins.noData", { defaultValue: "No admins found." })}
          </Typography>
        ) : (
          <ul className="users-list" style={{ direction: currentDirection }}>
            {filteredAdmins.map((admin) => (
              <li
                className="user-list-item"
                key={admin.id}
                onClick={() => openAdminModal(admin)}
                style={{ cursor: "pointer" }}
              >
                <p className="user-username">
                  {admin.username || "No Name Available"}
                </p>
                {admin.email && (
                  <Typography variant="subtitle1" color="textSecondary">
                    {admin.email}
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        )}

        <section className="pagination">
          <div className="pagination-buttons">
            <CustomButton
              variant="blank"
              title={t("previous")}
              onClick={() => setAdminsCurrentPage(adminsCurrentPage - 1)}
              disabled={adminsCurrentPage === 1}
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={15}
            />
            <span className="pagination-location">
              {t("page")}: {adminsCurrentPage} - {adminsTotalPages}
            </span>
            <CustomButton
              title={t("next")}
              variant="blank"
              onClick={() => setAdminsCurrentPage(adminsCurrentPage + 1)}
              disabled={adminsCurrentPage === adminsTotalPages}
              icon={currentDirection === "ltr" ? nextIcon : previousIcon}
              iconSize={15}
              reverseIcon
            />
          </div>

          <div className="pagination-buttons">
            <span className="pagination-location">
              {t("totalNumber")}: {adminsTotalRecords}
            </span>
          </div>
        </section>

        {adminModal && (
          <Modal
            title={isEditing ? t("editAdmin") : t("admin")}
            onClose={handleCancel}
            footer={
              <section className="edit-user-actions">
                {isEditing ? (
                  <>
                    <CustomButton
                      title={t("cancel")}
                      onClick={handleCancel}
                      variant="blank"
                      icon={closeIcon}
                      iconSize={14}
                    />
                    <CustomButton
                      title={t("confirm")}
                      onClick={handleSaveAdmin}
                      icon={saveIcon}
                      iconSize={16}
                    />
                  </>
                ) : (
                  <>
                    <CustomButton
                      title={t("delete")}
                      variant="red"
                      icon={deleteIcon}
                      iconSize={18}
                      onClick={() => openRemoveAdminModal(selectedAdmin)}
                    />
                    <CustomButton
                      variant="blank"
                      title={t("edit")}
                      onClick={() => setIsEditing(true)}
                      icon={editIcon}
                      iconSize={18}
                    />
                    <CustomButton
                      title={t("cancel")}
                      onClick={handleCancel}
                      variant="blank"
                      icon={closeIcon}
                      iconSize={14}
                    />
                  </>
                )}
              </section>
            }
          >
            <div>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("username")}: </span>
                      <CustomInput
                        name="userName"
                        value={adminFields.userName || ""}
                        onChange={(e) => handleInputChange(e, "userName")}
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p>{t("username")}:</p>
                      <p>{selectedAdmin?.username}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("email")}: </span>
                      <CustomInput
                        name="adminEmail"
                        value={adminFields.adminEmail || ""}
                        onChange={(e) => handleInputChange(e, "adminEmail")}
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p>{t("email")}:</p>
                      <p>{selectedAdmin?.email}</p>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </Modal>
        )}

        {showRemoveAdminModal && (
          <Modal
            title={t("deleteAdmin")}
            onClose={() => setRemoveAdminModal(false)}
            footer={
              <section className="delete-user-actions">
                <CustomButton
                  onClick={handleDeleteAdmin}
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                />
                <CustomButton
                  title={t("cancel")}
                  onClick={() => setRemoveAdminModal(false)}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
              </section>
            }
          >
            <div className="delete-modal-body">
              <h3>{t("areYouSureYouWantToDeleteThisUser")}</h3>
              <p>{selectedAdmin?.username || selectedAdmin?.email}</p>
            </div>
          </Modal>
        )}

        {/* Add Admin Modal */}
        {addAdminModal && (
          <Modal
            title={t("createAdmin")}
            onClose={handleCancelAddAdmin}
            footer={
              <section className="edit-user-actions">
                <CustomButton
                  title={t("cancel")}
                  onClick={handleCancelAddAdmin}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
                <CustomButton
                  title={t("confirm")}
                  onClick={handleCreateAdmin}
                  icon={saveIcon}
                  iconSize={16}
                />
              </section>
            }
          >
            <form onSubmit={handleCreateAdmin}>
              <div>
                {error && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ErrorBox>{errorMessage}</ErrorBox>
                  </div>
                )}
                <div className="edit-user-entity">
                  <span>{t("username")}: </span>
                  <CustomInput
                    name="userName"
                    value={newAdminFields.userName}
                    onChange={(e) => handleNewAdminInputChange(e, "userName")}
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("email")}: </span>
                  <CustomInput
                    name="adminEmail"
                    type="email"
                    value={newAdminFields.adminEmail}
                    onChange={(e) => handleNewAdminInputChange(e, "adminEmail")}
                    required
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("password")}: </span>
                  <CustomInput
                    name="password"
                    type="password"
                    value={newAdminFields.password}
                    onChange={(e) => handleNewAdminInputChange(e, "password")}
                    required
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("confirmPassword")}: </span>
                  <CustomInput
                    name="confirmPassword"
                    type="password"
                    value={newAdminFields.confirmPassword}
                    onChange={(e) =>
                      handleNewAdminInputChange(e, "confirmPassword")
                    }
                    required
                  />
                </div>
                <div className="edit-user-entity">
                  <div className="user-row-section">
                    <CustomCheckbox
                      checked={newAdminFields.isSuperAdmin}
                      onChange={(e) =>
                        handleNewAdminInputChange(e, "isSuperAdmin")
                      }
                      color="primary"
                    />
                    <label>{t("isSuperAdmin")}</label>
                  </div>
                </div>
                <div className="edit-user-entity">
                  <span>{t("superAdminPassword")}: </span>
                  <CustomInput
                    name="superAdminPassword"
                    type="password"
                    value={newAdminFields.superAdminPassword}
                    onChange={(e) =>
                      handleNewAdminInputChange(e, "superAdminPassword")
                    }
                    required={newAdminFields.isSuperAdmin}
                  />
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Container>
    </div>
  );
}
