// src/components/CustomButton
import { Component } from "react";
import classes from "./CustomButton.module.css";

export default function CustomButton({
  elementType,
  title,
  icon,
  iconSize,
  variant,
  minWidth,
  reverseIcon,
  disabled,
  ...props
}) {
  const Element = elementType ? elementType : "button";

  return (
    <Element
      className={`${classes.customButton} ${variant ? classes[variant] : ""} ${
        disabled ? classes.disabled : ""
      }`}
      style={{
        minWidth: minWidth || "",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1, 
      }}
      disabled={Element === "button" ? disabled : undefined} 
      {...props}
    >
      {!reverseIcon && icon && (
        <img
          src={icon}
          alt={title}
          style={{ width: iconSize + "px" }}
          className={classes.icon}
        />
      )}
      <span className={classes.title}>{title}</span>
      {reverseIcon && icon && (
        <img
          src={icon}
          alt={title}
          style={{ width: iconSize + "px" }}
          className={classes.icon}
        />
      )}
    </Element>
  );
}
