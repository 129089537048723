import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./ChartModal.scss";
import { useTranslation } from "react-i18next";
import useLanguage from "../utils/useLanguage";
import CustomButton from "../components/CustomButton";
import previousIcon from "../assets/previous_icon.png";
import nextIcon from "../assets/next_icon.png";

const ChartComponent = ({ data = [], chartType = "bar", dates = [] }) => {
  const { currentDirection } = useLanguage();
  const { t } = useTranslation("general");

  const chartRef = useRef(null);
  const [pageIndex, setPageIndex] = useState(0);

  // Pagination constants
  const itemsPerPage = 3;
  const totalPages = Math.ceil(dates.length / itemsPerPage);

  const getPaginatedData = () => {
    const start = pageIndex * itemsPerPage;
    const end = start + itemsPerPage;
    return {
      paginatedDates: dates.slice(start, end),
      paginatedReceived: data
        .slice(start, end)
        .map((row) => parseFloat(row?.received?.replace(/\s/g, "") || 0)),
      paginatedPayed: data
        .slice(start, end)
        .map((row) => parseFloat(row?.payed || 0)),
    };
  };

  const handleNext = () => {
    if (pageIndex < totalPages - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  useEffect(() => {
    // Destroy the previous chart instance when the component is mounted or chartType/pageIndex changes
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Get paginated data
    const { paginatedDates, paginatedReceived, paginatedPayed } =
      getPaginatedData();

    // Create a new chart instance
    const ctx = document.getElementById("myChart").getContext("2d");
    chartRef.current = new Chart(ctx, {
      type: chartType, // Use the dynamic chart type
      data: {
        labels: paginatedDates.length ? paginatedDates : ["Total Amount"], // Use paginated dates if provided, otherwise fallback to "Total Amount"
        datasets: [
          {
            label: "Received",
            data: paginatedReceived,
            backgroundColor:
              chartType === "doughnut"
                ? "rgba(222, 110, 18, 0.2)"
                : "rgba(222, 110, 18, 0.2)",
            borderColor: "rgba(222, 110, 18, 1)",
            borderWidth: 1,
          },
          {
            label: "Payed",
            data: paginatedPayed,
            backgroundColor:
              chartType === "doughnut"
                ? "rgba(23, 147, 194, 0.2)"
                : "rgba(23, 147, 194, 0.2)",
            borderColor: "rgba(23, 147, 194, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            display: chartType !== "doughnut", // Hide y-axis for doughnut charts
          },
        },
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.dataset.label}: ${context.raw}`;
              },
            },
          },
        },
      },
    });

    // Cleanup when the component is unmounted
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [chartType, pageIndex]);

  return (
    <div className="chart-holder">
      <canvas id="myChart" width="400" height="400"></canvas>
      <div className="chart-pagination-controls">
        <CustomButton
          variant="blank"
          title={t("previous")}
          onClick={handlePrevious}
          disabled={pageIndex === 0}
          icon={currentDirection === "ltr" ? previousIcon : nextIcon}
          iconSize={15}
        />
        <CustomButton
          title={t("next")}
          variant="blank"
          onClick={handleNext}
          disabled={pageIndex === totalPages - 1}
          icon={currentDirection === "ltr" ? nextIcon : previousIcon}
          iconSize={15}
          reverseIcon
        />
      </div>
    </div>
  );
};

export default ChartComponent;
