import useLanguage from "../utils/useLanguage";

const LanguageSwitcher = ({ variant }) => {
  const { language, changeLanguage } = useLanguage();

  if (language === "ar") {
    document.body.setAttribute("dir", "rtl");
  } else {
    document.body.setAttribute("dir", "ltr");
  }

  const mode = variant === "dark" ? "language-button-dark" : "";

  return (
    <div className="language-holder">
      <button
        className={`language-button  ${mode}`}
        onClick={() => changeLanguage("en")}
      >
        English
      </button>
      <button
        className={`language-button ${mode}`}
        onClick={() => changeLanguage("ar")}
      >
        العربية
      </button>
    </div>
  );
};

export default LanguageSwitcher;
