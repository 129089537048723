import { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import useLanguage from "../utils/useLanguage";
import { useNavigate } from "react-router";
import nextIcon from "../assets/next_icon.png";
import previousIcon from "../assets/previous_icon.png";
import editIcon from "../assets/edit_icon.png";
import closeIcon from "../assets/close_icon.png";

export default function SuppliersPage() {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [networkError, setNetworkError] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierModal, setSupplierModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [supplierFields, setSupplierFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Added pagination state
  const [totalPages, setTotalPages] = useState(1); // Added totalPages state
  const [totalRecords, setTotalRecords] = useState(0); // Added totalRecords state
  const navigate = useNavigate();

  useEffect(() => {
    getSuppliers();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    setFilteredSuppliers(
      suppliers.filter((supplier) =>
        supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, suppliers]);

  const getSuppliers = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getSuppliers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
          params: {
            page: currentPage,
            limit: 5,
            search: searchTerm,
          },
        }
      );

      if (response.data && response.data.suppliers) {
        setSuppliers(response.data.suppliers);
        setFilteredSuppliers(response.data.suppliers);
        setTotalPages(response.data.totalPages); // Set total pages from the response
        setTotalRecords(response.data.totalRecords); // Set total records from the response
      } else {
        console.error("Failed to get the suppliers");
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to get the suppliers", error);
    }
  };

  const openSupplierModal = (supplier) => {
    setSelectedSupplier(supplier);
    setSupplierFields({
      supplierName: supplier.name,
      supplierCompany: supplier.company,
      supplierLocation: supplier.location,
      supplierCurrency: supplier.currency,
    }); // Initialize fields for editing
    setIsEditing(false); // Set to editing mode
    setSupplierModal(true);
  };

  const handleInputChange = (e, fieldName) => {
    setSupplierFields({
      ...supplierFields,
      [fieldName]: e.target.value,
    });
  };

  const handleSaveSupplier = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/updateSupplier/${selectedSupplier.id}`,
        supplierFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (response.data.message === "Supplier updated successfully") {
        await getSuppliers(); // Refetch suppliers to get updated data
        setSupplierModal(false);
        console.log("Supplier updated successfully");
      } else {
        console.error("Failed to update supplier:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating supplier:", error);
    }
  };

  const handleCancel = () => {
    setSupplierModal(false);
    setSelectedSupplier(null);
  };

  return (
    <div className="view-users-page">
      <Container
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <CustomButton
          title={t("back")}
          variant="blank"
          icon={currentDirection === "ltr" ? previousIcon : nextIcon}
          iconSize={14}
          onClick={() => navigate("/dashboard")}
        />

        <Typography variant="h4" gutterBottom style={{ marginTop: "25px" }}>
          {t("suppliers", { defaultValue: "Suppliers" })}
        </Typography>

        <CustomInput
          name="supplierName"
          value={searchTerm}
          placeholder={searchTerm ? "" : t("search")}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {networkError ? (
          <Typography color="error">
            {t("error.network", { defaultValue: "Network error occurred." })}
          </Typography>
        ) : filteredSuppliers.length === 0 ? (
          <Typography>
            {t("suppliers.noData", { defaultValue: "No suppliers found." })}
          </Typography>
        ) : (
          <ul className="users-list" style={{ direction: currentDirection }}>
            {filteredSuppliers.map((supplier) => (
              <li
                className="user-list-item"
                key={supplier.id}
                onClick={() => openSupplierModal(supplier)}
                style={{ cursor: "pointer" }}
              >
                <p className="user-username">{supplier.name}</p>
                {supplier.company && (
                  <Typography variant="subtitle1" color="textSecondary">
                    {supplier.company}
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        )}

        <section className="pagination">
          <div className="pagination-buttons">
            <CustomButton
              variant="blank"
              title={t("previous")}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={15}
            />
            <span className="pagination-location">
              {t("page")}: {currentPage} - {totalPages}
            </span>
            <CustomButton
              title={t("next")}
              variant="blank"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              icon={currentDirection === "ltr" ? nextIcon : previousIcon}
              iconSize={15}
              reverseIcon
            />
          </div>

          <div className="pagination-buttons">
            <span className="pagination-location">
              {t("totalNumber")}: {totalRecords}
            </span>
          </div>
        </section>

        {supplierModal && (
          <Modal
            title={isEditing ? t("editSupplier") : t("supplier")}
            onClose={handleCancel}
            footer={
              <section className="edit-user-actions">
                {isEditing ? (
                  <>
                    <CustomButton
                      onClick={handleSaveSupplier}
                      title={t("confirm")}
                    />
                    <CustomButton
                      title={t("cancel")}
                      onClick={handleCancel}
                      variant="blank"
                    />
                  </>
                ) : (
                  <>
                    <CustomButton
                      variant="blank"
                      title={t("edit")}
                      onClick={() => setIsEditing(true)}
                      icon={editIcon}
                      iconSize={18}
                    />
                    <CustomButton
                      title={t("cancel")}
                      onClick={handleCancel}
                      variant="blank"
                      icon={closeIcon}
                      iconSize={14}
                    />
                  </>
                )}
              </section>
            }
          >
            <div>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("supplierName")}: </span>
                      <CustomInput
                        name="supplierName"
                        value={supplierFields.supplierName || ""}
                        onChange={(e) => handleInputChange(e, "supplierName")}
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("supplierName")}:</p>
                      <p variant="body1">{selectedSupplier?.name}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("supplierCompany")}: </span>
                      <CustomInput
                        name="supplierCompany"
                        value={supplierFields.supplierCompany || ""}
                        onChange={(e) =>
                          handleInputChange(e, "supplierCompany")
                        }
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("supplierCompany")}:</p>
                      <p variant="body1">{selectedSupplier?.company}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("supplierLocation")}: </span>
                      <CustomInput
                        name="supplierLocation"
                        value={supplierFields.supplierLocation || ""}
                        onChange={(e) =>
                          handleInputChange(e, "supplierLocation")
                        }
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("supplierLocation")}:</p>
                      <p variant="body1">{selectedSupplier?.location}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  <div className="view-user-entity">
                    <p variant="body1">{t("supplierCurrency")}:</p>
                    <p variant="body1">{selectedSupplier?.currency}</p>
                  </div>
                </div>
              </section>
            </div>
          </Modal>
        )}
      </Container>
    </div>
  );
}
