import React, { useState, useEffect } from "react";
import Image from "./Image";
import classes from "./PDFSlider.module.css";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";

import printIcon from "../assets/printer_icon.png";
import PDFViewer from "./PDFViewer";

const DIRECTION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const AllDocumentsSlider = ({
  documents,
  direction = DIRECTION.HORIZONTAL,
  title,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const { t } = useTranslation("general");

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const handlePrint = async () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <html>
          <head>
            <title>${t("print_image")}</title>
          </head>
          <body>
            <img style="max-width: 100%; max-height: 100vh" src="${selectedImage}" alt="Image for printing" onload="window.print();" />
          </body>
        </html>
      `);
    printWindow.document.close();
  };

  return (
    <div className={classes.sliderContainer}>
      <span>{t(title)}: </span>
      <div
        className={
          direction === DIRECTION.HORIZONTAL
            ? classes.pdfSliderHorizontal
            : classes.pdfSliderVertical
        }
      >
        {documents?.length === 0 ? (
          <span className="not-found">{t("noDocuments")}</span>
        ) : null}
        {documents?.map((document, index) => {
          if (document.mime_type === "application/pdf") {
            return (
              <div className={classes.document} key={index}>
                <div className={classes.sliderImage}>
                  <PDFViewer
                    fileId={document?.path}
                    fileName={document?.name}
                  />
                </div>
                <span className={classes.fileName}>{document?.name}</span>
              </div>
            );
          } else {
            if (document?.path)
              return (
                <div className={classes.document} key={index}>
                  <Image
                    imagePath={document?.path}
                    className={classes.sliderImage}
                    onClick={openModal}
                  />
                  <span className={classes.fileName}>{document?.name}</span>
                </div>
              );
          }
        })}
      </div>

      {selectedImage && (
        <div className={classes.overlay}>
          <div className={classes.modal}>
            <div className={classes.modalHeader}>
              <span className={classes.close} onClick={closeModal}>
                &times;
              </span>
            </div>
            <div className={classes.modalBody}>
              <img
                className={classes.modalContent}
                src={selectedImage}
                alt="Selected"
              />
            </div>
            <div className={classes.modalFooter}>
              <CustomButton
                title={t("print")}
                onClick={handlePrint}
                variant="gray"
                icon={printIcon}
                iconSize={18}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllDocumentsSlider;
