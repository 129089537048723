// import useLanguage from "../../hooks/useLanguage";
import classes from "./InformationBox.module.css";
import infoIcon from "../assets/info_icon.svg";

export default function InformationBox({ children }) {

  return (
    <div
      className={classes.InformationBox}
      // style={{ direction: pageDirection }}
    >
      <img src={infoIcon} alt="Information box" />
      <p>{children}</p>
    </div>
  );
}
