// src/utils/DataContext.js
import { useState, useEffect, createContext, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const DataContext = createContext(null);

export default function DataProvider({ children }) {
  const { t } = useTranslation("general");

  const [searchError, setSearchError] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState("");

  const currencies = [
    { title: "USD", value: "USD" },
    { title: "IQD", value: "IQD" },
    { title: "EURO", value: "EURO" },
    { title: "RMB", value: "RMB" },
    { title: "TRY", value: "TRY" },
  ];

  const [invoices, setInvoices] = useState([]);
  const [selectedInvoicesStatus, setSelectedInvoicesStatus] = useState({
    title: "Active",
    value: "active",
  });

  const [selectedTitle, setSelectedTitle] = useState({
    title: t("Invoice Number"),
    value: "invoiceNumber",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [suppliers, setSuppliers] = useState([]);

  // * Pagination
  const [invoicesCurrentPage, setInvoicesCurrentPage] = useState(1);
  const [invoicesTotalPages, setInvoicesTotalPages] = useState(1);
  const [invoicesTotalRecords, setInviocesTotalRecords] = useState(1);
  const [networkError, setNetworkError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const [currentBalance, setCurrentBalance] = useState(null);

  const [supplierModal, setSupplierModal] = useState(false);
  const [supplierFields, setSupplierFields] = useState({
    supplierName: "",
    supplierCompany: "",
    supplierLocation: "",
  });

  const [supplierCurrency, setSupplierCurrency] = useState({
    title: "USD",
    value: "USD",
  });

  const [selectedSupplier, setSelectedSupplier] = useState({});

  useEffect(() => {
    const getAllSuppliers = async () => {
      await getSuppliers();
    };

    getAllSuppliers();

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const getCurrentBalance = async () => await fetchCurrentBalance();
    if (selectedSupplier) {
      getCurrentBalance();
    }
  }, [selectedSupplier.id]);

  useEffect(() => {
    const getNewInvoicesPage = async () => {
      if (selectedInvoicesStatus.value === "active") {
        if (searchQuery) {
          await handleSearch();
        } else {
          await fetchInvoices(selectedSupplier?.id);
        }
      } else if (selectedInvoicesStatus.value === "archived") {
        await fetchArchivedInvoices(selectedSupplier?.id);
      } else {
        console.log("Please select valid type of invoices");
      }
    };

    if (selectedSupplier?.id) {
      getNewInvoicesPage();
    }
  }, [invoicesCurrentPage]);

  const getSuppliers = async () => {
    const token = localStorage.getItem("token");
    // Ensure userId is stored as a number
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      const suppliers = await axios.get(
        `${process.env.REACT_APP_URL}/getAllSuppliers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (!suppliers) {
        console.error("Failed to get the suppliers", error);
      }

      const existingSupliers = suppliers?.data?.data;

      if (existingSupliers?.length > 0) {
        setSuppliers(existingSupliers);
        existingSupliers?.map(async (supplier) => {
          if (supplier.isLastSupplier) {
            setSelectedSupplier(supplier);
            await fetchInvoices(supplier?.id);
          }
        });
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to get the suppliers", error);
    }
  };

  const fetchInvoices = async (supplierId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (supplierId) {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/getInvoices`,
          {
            params: { page: invoicesCurrentPage, limit: 10 },
            headers: {
              Authorization: `Bearer ${token}`, 
              "User-Id": userId,
              "Supplier-Id": supplierId || selectedSupplier?.id,
            },
          }
        );
  
        if (response.data) {
          setInvoices(response.data?.invoices);
          setInvoicesTotalPages(response.data?.totalPages);
          setInviocesTotalRecords(response.data?.totalInvoices);
        }
  
        setLoading(false);
        return response.data;
      } else {
        setLoading(true);
        return
      }
    } catch (error) {
      setNetworkError(true);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };


  const [selectedDate, setSelectedDate] = useState(null);
  const handleSearch = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    if (selectedSupplier?.id) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/searchInvoices`,
          {
            params: {
              query: searchQuery,
              title: selectedTitle.value,
              dateToSearchFor: selectedDate,
              page: invoicesCurrentPage,
              invoicesStatus: selectedInvoicesStatus.value,
              limit: 10,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
              "Supplier-Id": selectedSupplier?.id,
            },
          }
        );

        if (!response) {
          console.error("Failed to get the search results");
        }

        setInvoices(response?.data?.searchResults);
        setInvoicesCurrentPage(response?.data?.currentPage);
        setInviocesTotalRecords(response?.data?.totalInvoices);
        setInvoicesTotalPages(response?.data?.totalPages);
        setCurrentBalance(response?.data?.currentBalance);
      } catch (error) {
        setSearchError(true);
        setSearchErrorMessage(error.response.data.error);
        console.error("Error searching invoices:", error);
      }
    }
  };

  const fetchArchivedInvoices = async (supplierId) => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/getArchivedInvoices`,
        {
          params: { page: invoicesCurrentPage, limit: 10 },

          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": supplierId,
          },
        }
      );
      if (res.data) {
        setInvoices(res?.data?.invoices);
        setInvoicesTotalPages(res.data?.totalPages);
        setInviocesTotalRecords(res.data?.totalInvoices);
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Falied to get the archived invoices", error);
    }
  };

  const fetchCurrentBalance = async () => {
    if (selectedSupplier?.id) {
      try {
        const token = localStorage.getItem("token");
        const userId = parseInt(localStorage.getItem("userId"), 10);

        const response = await axios.get(
          `${process.env.REACT_APP_URL}/getCurrentBalance`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
              "supplier-Id": selectedSupplier?.id,
            },
          }
        );

        setCurrentBalance(response?.data);
      } catch (error) {
        setNetworkError(true);
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleShowSupplierModal = () => {
    setSupplierModal(true);
  };

  const handleSaveSupplier = async () => {
    const token = localStorage.getItem("token");
    // Ensure userId is stored as a number
    const userId = parseInt(localStorage.getItem("userId"), 10);
    
    if (!supplierFields?.supplierName) {
      setError("noSupplierName");
      return;
    }

    try {
      const newSupplier = await axios.post(
        `${process.env.REACT_APP_URL}/createSupplier`,
        {
          ...supplierFields,
          supplierCurrency: supplierCurrency.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (!newSupplier) {
        console.error("Failed to save the supplier", error);
      }
      setSupplierModal(false);
      setSupplierFields({
        supplierName: "",
        supplierCompany: "",
        supplierLocation: "",
      });
      setSupplierCurrency({
        title: "USD",
        value: "USD",
      });

      const newSupplierData = newSupplier?.data?.newSupplier;
      // console.log("newSupplier.data", newSupplier.data.newSupplier);
      setSuppliers((prev) => [newSupplierData, ...prev]);

      if (newSupplierData?.isLastSupplier) {
        setSelectedSupplier(newSupplierData);
        localStorage.setItem("supplierId", newSupplierData?.id);
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to save the supplier", error);
    }
  };

  const handleSelectSupplier = async (supplier) => {
    setSelectedSupplier(supplier);
    await fetchInvoices(supplier?.id);
    setSearchQuery("");
    localStorage.setItem("supplierId", supplier?.id);
  };

  return (
    <DataContext.Provider
      value={{
        suppliers,
        setSuppliers,
        supplierModal,
        setSupplierModal,
        selectedSupplier,
        setSelectedSupplier,
        handleSelectSupplier,
        handleShowSupplierModal,
        error,
        setError,
        networkError,
        setNetworkError,
        loading,
        setLoading,
        data: invoices,
        setData: setInvoices,
        currentBalance,
        setCurrentBalance,
        handleSaveSupplier,
        supplierFields,
        supplierCurrency,
        setSupplierCurrency,
        setSupplierFields,
        invoicesCurrentPage,
        setInvoicesCurrentPage,
        invoicesTotalPages,
        invoicesTotalRecords,
        fetchInvoices,
        fetchArchivedInvoices,
        handleSearch,
        selectedDate,
        setSelectedDate,
        setInvoicesTotalPages,
        setInviocesTotalRecords,
        selectedInvoicesStatus,
        setSelectedInvoicesStatus,
        searchQuery,
        setSearchQuery,
        selectedTitle,
        setSelectedTitle,
        currencies,
        searchError,
        searchErrorMessage,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
